<template>
  <v-container>
    <div>
      <h1 v-html="$t('product_group_edit_title')"></h1>
      <FormJsonSchema
        v-if="model && schema"
        ref="formJsonSchema"
        v-model:model="model"
        :schema="schema"
        domainmodel="editProductGroups"
        @submit="submit"
        @reset="reset"
      />

      <v-btn color="secondary" @click="goBack"><span v-html="$t('product_group_edit_button_back')" /></v-btn>
      <v-btn color="primary" @click="submit"><span v-html="$t('product_group_edit_button_update')" /></v-btn>
    </div>
  </v-container>
</template>
<script lang="ts">
import FormJsonSchema from '../../FormJsonSchema'
import schema from './productGroupsSchema.json'
import { apiURL } from '../../../main'

import _ from 'lodash'

const defaultData = {
  id: 0,
  modification: null,
  modifierId: 0,
  creation: null,
  creatorId: 0,
  nameDe: 'Gruppe1',
  nameFr: 'Groupe',
  nameIt: 'Gruppo',
  hyid: 'string',
  rootId: 0,
  parentId: 0,
  parentGuid: 'string',
  pos: 0,
  ident: 0,
  validUntil: null
}
import BaseMixin from '../../../utils/BaseMixin'
import { showError } from '@/services/axios'

//https://www.npmjs.com/package/vue-form-schema
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'editProductGroups',
  data() {
    return {
      apiURL: apiURL + '/productGroups/',
      productGroupsUrl: '/productGroups/',
      schema: schema,
      // adding a key allows the component to rerender on url change
      key: this.$route.path,
      allItems: [],
      productGroupId: 0,
      fields: [
        { key: 'creation', sortable: true },
        { key: 'creatorId', sortable: true },
        { key: 'hyid', sortable: true },
        { key: 'id', sortable: true },
        { key: 'ident', sortable: true },
        { key: 'modification', sortable: true },
        { key: 'modifierId', sortable: true },
        { key: 'nameDe', sortable: true },
        { key: 'nameFr', sortable: true },
        { key: 'nameIt', sortable: true },
        { key: 'parentId', sortable: true },
        { key: 'parent_guid', sortable: true },
        { key: 'pos', sortable: true },
        { key: 'rootId', sortable: true },
        { key: 'valid_until', sortable: true }
      ],
      term: { nameDE: '', pos: '' },
      model: defaultData
    }
  },
  mixins: [BaseMixin],
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async loadProductGroup(productGroupId) {
      try {
        const result = await this.axios.get((this.apiURL += productGroupId), { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.model = result.data
          return result.data
        }
      } catch (err) {
        showError(err)
      }
    },
    async submit(e) {
      e.preventDefault()
      try {
        await this.axios.put(this.apiURL, { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
      } catch (err) {
        showError(err)
      }
    },
    reset() {
      // im a hook
    },
    filterAPIFieldsX(jsonSchema) {
      delete jsonSchema.properties.modification
      delete jsonSchema.properties.modifierId
      delete jsonSchema.properties.creation
      delete jsonSchema.properties.creatorId
      delete jsonSchema.properties.hyid
      return jsonSchema
    }
  },
  components: { FormJsonSchema },
  mounted() {
    this.schema = this.filterAPIFieldsX(schema)
    this.$nextTick(async () => {
      const productGroupId = this.$route.params.id
      this.productGroup = await this.loadProductGroup(productGroupId)
      this.model = _.omit(this.model, ['modification', 'modifierId', 'creation', 'creatorId', 'hyid', 'parentGuid', 'guid', 'validUntil'])
    })
  }
})
</script>
